import { css } from "@emotion/css";
import { Button, resetStyles } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { NoProjectsIllustration } from "../NoProjectsIllustration";
import type { EmptyProjectsState } from "./ControlledProjectSwitcher";
export interface NoProjectsProps {
    projectsState: EmptyProjectsState;
}
export function NoProjects({ projectsState }: NoProjectsProps) {
    const { onAddNewProjectRequested, hasProjectCreatePermission } = projectsState;
    return (<div className={noProjectsStyles}>
            <NoProjectsIllustration />
            <h3 className={noProjectsHeadingStyles}>No projects found</h3>
            <div className={noProjectsCopyStyles}>
                <span>New to Octopus Deploy?</span>
                <span>Create your first project to get started.</span>
            </div>
            {hasProjectCreatePermission && <Button label="Add New Project" importance="primary" onClick={onAddNewProjectRequested}/>}
        </div>);
}
const noProjectsStyles = css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: space[16],
    padding: space[16],
});
const noProjectsHeadingStyles = css({
    ...resetStyles.heading,
    font: text.heading.large,
    color: themeTokens.color.text.primary,
});
const noProjectsCopyStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    font: text.regular.default.large,
    color: themeTokens.color.text.secondary,
});
